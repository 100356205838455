import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import store from "./store";

// Regular imports for non-page components
import TranslationProvider from "./TransaltionProvider";
import ConfigurationComponent from "./components/ConfigurationComponsnt";

// Lazy loading the Menu and page components
const Menu = lazy(() => import("./components/Menu"));
const Contact = lazy(() => import("./pages/Contact"));
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const RequestPage = lazy(() => import("./pages/Request"));
const MyRequests = lazy(() => import("./pages/Requests"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const RequestDetails = lazy(() => import("./pages/RequestDetails"));
const Profile = lazy(() => import("./pages/Profile"));

function App() {
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <Router>
        <TranslationProvider>
          <ConfigurationComponent />
          <div className="App">
            <header>
              <div>
                <h1>{t("welcomeMessage")}</h1>
              </div>
            </header>

            {/* Suspense fallback for the lazy-loaded Menu */}
            <Suspense>
              <Menu />
            </Suspense>

            <main>
              {/* Suspense fallback for lazy-loaded components */}
              <Suspense>
                <Routes>
                  <Route path="/:lng" element={<Home />} />
                  <Route path="/:lng/contact" element={<Contact />} />
                  <Route path="/:lng/login" element={<Login />} />
                  <Route path="/:lng/request" element={<RequestPage />} />
                  <Route path="/:lng/request/mine" element={<MyRequests />} />
                  <Route
                    path="/:lng/forgot-password"
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="/:lang/request/:id"
                    element={<RequestDetails />}
                  />
                  <Route path="/:lang/profile" element={<Profile />} />
                </Routes>
              </Suspense>
            </main>
          </div>
        </TranslationProvider>
      </Router>
    </Provider>
  );
}

export default App;
